import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { FC } from "react";
interface Props {
  open: boolean;
  handleClose: () => void;
  errors: any[];
}

const errorKeyMapping = {
  firstName: "姓",
  lastName: "名",
  firstPostId: "郵便番号",
  lastPostId: "郵便番号",
  country: "市区町村",
  area: "都道府県",
  block: "番地",
  building: "建物名・部屋番号など",
  phone: "携帯番号",
  // phoneAgain: "携帯番号確認のため再度ご入力下さい",
  mail: "メールアドレス",
  // mailAgain: "メールアドレス確認のため再度ご入力下さい",
};
const ErrorItem = ({ error }: any) => {
  const errorItem =
    error === "firstPostId" || error === "lastPostId"
      ? "郵便番号"
      : errorKeyMapping[error];
  return (
    errorItem && (
      <Typography
        sx={{
          fontSize: "0.8rem",
          fontWeight: 700,
        }}
      >
        -{errorItem}
      </Typography>
    )
  );
};

export const FieldErrorDialog: FC<Props> = ({ open, handleClose, errors }) => {
  const dialogType =
    (!!errors["phoneAgain"] || !!errors["mailAgain"]) &&
    Object.keys(errorKeyMapping).every(item => !errors[item]);
  const isShowOneItem = errors["firstPostId"] && errors["lastPostId"];

  return (
    <>
      {dialogType ? (
        <Dialog
          disableAutoFocus
          disableRestoreFocus
          open={open}
          sx={{
            // "& .MuiDialogContent-root": {
            //   padding: theme.spacing(3),
            // },
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",
              },
            },

            "& .MuiDialog-paperWidthMd": {
              minWidth: "600px",
            },
          }}
        >
          <DialogContent
            sx={{
              background: "white",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: {
                sm: "100%",
                md: "600px",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: 700,
              }}
              variant="h4"
            >
              記入項目にエラーがあります{" "}
            </Typography>
            <Typography
              sx={{
                mt: 3,
                fontSize: "0.8rem",
                fontWeight: 700,
              }}
            >
              携帯電話番号またはメールアドレスに誤りがあります。
            </Typography>
            <Typography
              sx={{
                mb: 3,
                fontSize: "0.8rem",
                fontWeight: 700,
              }}
            >
              再度入力してから送信してください。
            </Typography>
            <Button
              sx={{ width: "30%" }}
              onClick={() => handleClose()}
              variant="contained"
            >
              OK
            </Button>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          disableAutoFocus
          disableRestoreFocus
          open={open}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",
              },
            },

            "& .MuiDialog-paperWidthMd": {
              minWidth: "600px",
            },
          }}
        >
          <DialogContent
            sx={{
              background: "white",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: {
                sm: "100%",
                md: "600px",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: 700,
              }}
              variant="h4"
            >
              未記入の項目があります
            </Typography>
            <Typography
              sx={{
                mt: 3,
                fontSize: "0.8rem",
                fontWeight: 700,
              }}
            >
              以下の項目が未記入です。記入してから再度送信してください。
            </Typography>

            <Box
              sx={{
                mb: 3,
              }}
            >
              {errors &&
                Object.keys(errors).map(item => {
                  return isShowOneItem && item === "lastPostId" ? null : (
                    <ErrorItem key={item} error={item} />
                  );
                })}
            </Box>
            <Button
              sx={{ width: "50%" }}
              onClick={() => handleClose()}
              variant="contained"
            >
              OK
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
