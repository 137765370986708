import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00008 13.334C3.77845 13.334 1.16675 10.7223 1.16675 7.50065C1.16675 4.27899 3.77845 1.66732 7.00008 1.66732C10.2217 1.66732 12.8334 4.27899 12.8334 7.50065C12.8334 10.7223 10.2217 13.334 7.00008 13.334ZM7.00008 6.91732C7.32092 6.91732 7.58342 7.17982 7.58342 7.50065V9.83398C7.58342 10.1548 7.32092 10.4173 7.00008 10.4173C6.67925 10.4173 6.41675 10.1548 6.41675 9.83398V7.50065C6.41675 7.17982 6.67925 6.91732 7.00008 6.91732ZM7.00008 4.58398C6.67792 4.58398 6.41675 4.84515 6.41675 5.16732C6.41675 5.48948 6.67792 5.75065 7.00008 5.75065C7.32225 5.75065 7.58342 5.48948 7.58342 5.16732C7.58342 4.84515 7.32225 4.58398 7.00008 4.58398Z"
          fill="#BBBCBC"
        />
      </svg>
    </SvgIcon>
  );
}
