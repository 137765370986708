import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export const useFormType = () => {
  const { pathname } = useLocation();

  const getType = useCallback(() => {
    if (pathname.includes("a")) {
      return "A";
    }
    if (pathname.includes("b")) {
      return "B";
    }
    if (pathname.includes("c")) {
      return "C";
    }
  }, []);

  return getType();
};
