import { Box, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import React, { FC, useContext, useEffect, useState } from "react";
import { FormProviderContext } from "../context/FormProvider";
import { useLocation } from "react-router-dom";
import { TimeProviderContext } from "@/components/context/TimeProvider";
const TotalPage = {
  a: 2,
  b: 4,
  c: 4,
};
export const PageButton: FC<{
  onClickSubmit: (data: any) => void;
  isEnableButton?: boolean;
}> = ({ onClickSubmit, isEnableButton = true }) => {
  const { page, setPage } = useContext(FormProviderContext);
  const [type, setType] = useState("");
  const theme = useTheme();
  const top = useMediaQuery(theme.breakpoints.down("md")) ? 0 : 300;

  const { setReset } = useContext(TimeProviderContext);

  const handleClick = () => {
    onClickSubmit && onClickSubmit({ step: "next" });
    setReset && setReset(true);
    window.scroll({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleBack = () => {
    onClickSubmit && onClickSubmit({ step: "pre" });
    setReset && setReset(true);
    setPage && setPage(page - 1);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("a")) {
      setType("a");
    }
    if (pathname.includes("b")) {
      setType("b");
    }
    if (pathname.includes("c")) {
      setType("c");
    }
  }, []);

  return (
    <Box
      mt={4}
      pt={4}
      pb={1}
      sx={{
        display: "flex",
        justifyContent: "end",
        backgroundColor: "#F0F0F0",
        borderTop: "1px dashed #BBBCBC",
      }}
    >
      {page && page > 1 && (
        <Button
          sx={{ mr: 3, width: "150px", py: 1.2 }}
          onClick={() => handleBack()}
        >
          戻る
        </Button>
      )}
      <Button
        sx={{ width: "150px", py: 1.2 }}
        onClick={handleClick}
        variant="contained"
        disabled={!isEnableButton}
      >
        {page === TotalPage[type] ? "送信する" : "次へ >"}
      </Button>
    </Box>
  );
};
