import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useFormType } from "@/components/share/useFormType";

const FinishPage = () => {
  const type = useFormType();

  useEffect(() => {
    const page = type === "A" ? 3 : 5;
    gtag("event", "page_view", {
      page_title: `Type${type}-STEP${page}（申込完了）`,
      page_location: `/form/${type?.toLowerCase()}/completedapplication`,
    });
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          p: 3,
          boxShadow: "0px 2px 4px 0px #00000045",
          background: "white",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minWidth: {
            sm: "100%",
            md: "400px",
          },
        }}
      >
        <Box
          component="img"
          sx={{
            height: "17%",
            width: "17%",
          }}
          alt="The house from the offer."
          src="/images/check.svg"
        />
        <Typography
          sx={{
            mt: 2,
            fontSize: "1.2rem",
            fontWeight: 700,
          }}
          variant="h4"
        >
          お申し込み完了
        </Typography>
        <Box
          sx={{
            mb: 2,

            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              mt: 3,
              fontSize: "0.8rem",
              fontWeight: 700,
            }}
          >
            環境価値の地域循環サービスプラットフォームにお申込みいただきありがとうございます。
          </Typography>
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            後ほど担当者より、環境価値創出のための専用 IoT
            機器設置に関するご案内をメールにて送らせて頂きます。今しばらくお待ちください。{" "}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default FinishPage;
