export function getCookie(key: string): string {
  const allCookies = document.cookie;

  const cookiesArray = allCookies.split(";");

  for (let i = 0; i < cookiesArray.length; i++) {
    const cookie = cookiesArray[i].trim();
    if (cookie.indexOf(key + "=") === 0) {
      return cookie.substring(key.length + 1, cookie.length);
    }
  }
  return "";
}

export function setCookie(key: string, value: string, expiry?: Date): void {
  let cookieString = `${key}=${value}`;
  if (expiry) {
    cookieString = cookieString + `; expires=${expiry.toUTCString()}`;
  }
  document.cookie = cookieString;
}

export function deleteCookie(key: string): void {
  document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
}
