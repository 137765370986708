import React, { FC, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { CommonInput } from "@/components/basic/CommonInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, InputAdornment, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";

const Form: FC = () => {
  const schema = yup.object().shape({
    password: yup
      .string()
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]{10,20}$/,
        {
          message:
            "10〜20 文字の長さで、英字の大文字と小文字、数字、記号が全て含まれている必要があります。",
          excludeEmptyString: true,
        },
      )
      .required("パスワードは入力必須項目です。"),
    passwordAgain: yup
      .string()
      .required("入力されたパスワードが一致しません。")
      .oneOf(
        [yup.ref("password"), null],
        "入力されたパスワードが一致しません。",
      ),
  });

  const navigate = useNavigate();
  const { token } = useParams();

  const {
    control,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      passwordAgain: "",
      password: "",
    },
  });

  const updatePassword = async ({ password }: { password: string }) => {
    try {
      await axios.post(
        `${process.env.VITE_API_URL}/admin/update_password`,
        {
          new_password: password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setIsError(false);
      setOpen(true);
      setTitle("パスワードを変更しました");
      setContent("新しいパスワードでログインしてください。");
    } catch (e) {
      const errorMsg = e?.response?.status;
      setIsError(true);
      setOpen(true);
      setTitle("問題が発生しました");
      setContent(
        `問題が発生しました。しばらくしてから再度お試しいただくか、サービスプロバイダまでお問い合わせください。${
          errorMsg ? "(" + errorMsg + ")" : ""
        }`,
      );
    }
  };

  const handleClick = () => {
    handleSubmit(
      value => {
        updatePassword({ password: value.password });
      },
      () => {},
    )();
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const [showCheckPassword, setShowCheckPassword] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isError, setIsError] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleClickShowCheckPassword = () =>
    setShowCheckPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <CommonInput
        sx={{ pb: "8px", mb: 2 }}
        name="password"
        placeholder={"新しいパスワードを入力"}
        control={control}
        errors={errors}
        label={"新しいパスワード"}
        variant="standard"
        labelColor={errors["password"] ? "#F44336" : "#757575"}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <CommonInput
        sx={{ paddingBottom: "8px" }}
        name="passwordAgain"
        placeholder={"再度新しいパスワードを入力"}
        control={control}
        errors={errors}
        labelColor={errors["passwordAgain"] ? "#F44336" : "#757575"}
        label={"新しいパスワード（確認）"}
        variant="standard"
        type={showCheckPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowCheckPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showCheckPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <Button
        sx={{ width: "100%", mt: 5, height: "50px" }}
        onClick={handleClick}
        variant="contained"
      >
        設定
      </Button>
      <Dialog
        disableAutoFocus
        disableRestoreFocus
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },

          "& .MuiDialog-paperWidthMd": {
            minWidth: "600px",
          },
        }}
      >
        <DialogContent
          sx={{
            background: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 5,
            minWidth: {
              sm: "100%",
              md: "600px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: 700,
            }}
            variant="h4"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              mt: 3,
              fontSize: "0.8rem",
              fontWeight: 700,
            }}
          >
            {content}
          </Typography>

          <Button
            sx={{ width: "30%", mt: 3 }}
            onClick={() => {
              setOpen(false);
              !isError && navigate(`/login`);
            }}
            variant="contained"
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ResetPwdForm: FC = () => {
  return (
    <Box
      mt={2}
      p={2}
      sx={{
        backgroundColor: "white",
        borderRadius: "5px",
        width: "50%",
      }}
    >
      <Typography
        id="top"
        variant="h2"
        component="h1"
        my={6}
        sx={{
          fontWeight: 700,
          fontSize: "2.5rem",
        }}
      >
        パスワード設定
      </Typography>
      <Form />
    </Box>
  );
};

export default ResetPwdForm;
