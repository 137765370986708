import React from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { Control, Controller } from "react-hook-form";
import { Box } from "@mui/material";
import FormLabel from "@/components/basic/FormLabel";
import { DatePicker } from "@mui/x-date-pickers";

type CommonInputProps = {
  maxLength?: number;
  label?: string;
  control: Control<any, any>;
  error?: boolean;
  errorMessage?: string;
  errors: {
    [x: string]: {
      message?: string;
    };
  };
  name: string;
  onChangeAction?: () => void;
  placeholder: string;
  info?: any;
} & TextFieldProps;

export const CommonDatePicker: React.FunctionComponent<CommonInputProps> = ({
  onChangeAction,
  ...props
}) => {
  const { defaultValue, info, required, name, label } = props;

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange } }) => (
        <Box
          sx={{
            display: "flex",
            // justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <FormLabel
            required={required}
            name={name}
            label={label}
            info={info}
          />
          <DatePicker
            disableFuture
            value={defaultValue}
            onChange={e => {
              onChange(e);
              onChangeAction && onChangeAction();
            }}
            label={""}
            // error={!!errors[name]}
            // helperText={errorMessage || errors[name]?.message}
            slotProps={{
              layout: {
                sx: {
                  backgroundColor: "#FFFFFF",
                  ".MuiPickersToolbar-root": {
                    display: "none",
                  },
                },
              },
              inputAdornment: { position: "start" },
              textField: {
                size: "small",
                placeholder: "選択する",
              },
            }}
            sx={{
              height: "40px",
              backgroundColor: "#FFFFFF",
              ".MuiPickersToolbar-content": {
                display: "none",
              },
            }}
          />
        </Box>
      )}
    />
  );
};
