import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { TimeProviderContext } from "@/components/context/TimeProvider";
const TimeCount = () => {
  const { timePopupStatus } = useContext(TimeProviderContext);

  return (
    <Dialog
      disableAutoFocus
      disableRestoreFocus
      open={timePopupStatus || false}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",
          },
        },

        "& .MuiDialog-paperWidthMd": {
          minWidth: "600px",
        },
      }}
    >
      <DialogContent
        sx={{
          background: "white",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 5,
          minWidth: {
            sm: "100%",
            md: "600px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontWeight: 700,
          }}
          variant="h4"
        >
          接続がタイムアウトしました{" "}
        </Typography>
        <Typography
          sx={{
            mt: 3,
            fontSize: "0.8rem",
            fontWeight: 700,
          }}
        >
          再度始めから入力してください。
        </Typography>

        <Button
          sx={{ width: "30%", mt: 3 }}
          onClick={() => {
            window.location.reload();
          }}
          variant="contained"
        >
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default TimeCount;
