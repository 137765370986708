import { Box } from "@mui/material";
import Select, { SelectProps } from "@mui/material/Select/Select";
import React, { ReactNode } from "react";

import { Control, Controller } from "react-hook-form";
import FormLabel from "@/components/basic/FormLabel";

type CommonSelectProps = {
  placeholder: string;
  label?: string;
  control: Control<any, any>;
  errors: {
    [x: string]: {
      message?: string;
    };
  };
  error?: boolean;
  errorMessage?: string;
  name: string;
  children: ReactNode;
  onChangeAction?: () => void;
  renderValue: any;
} & SelectProps;

export const CommonSelect: React.FunctionComponent<CommonSelectProps> = ({
  onChangeAction,
  ...props
}) => {
  const { renderValue, control, required, name, label, errors } = props;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <Box>
          <FormLabel required={required} name={name} label={label} />
          <Select
            variant="outlined"
            color="primary"
            id={name}
            size="small"
            inputProps={{
              sx: { backgroundColor: "#FFFFFF" },
              placeholder: "選択する",
            }}
            displayEmpty={true}
            placeholder={"選択する"}
            value={value}
            onChange={event => {
              onChange(event);
              onChangeAction && onChangeAction();
            }}
            error={!!errors[name]}
            sx={{
              height: "40px",
              width: "100%",
            }}
            renderValue={renderValue}
          >
            {props.children}
          </Select>
        </Box>
      )}
    />
  );
};
