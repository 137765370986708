import { SxProps, Theme, Typography } from "@mui/material";
import React, { FC } from "react";
type Props = {
  text: string;
  sx?: SxProps<Theme>;
};
const FormGroupTitle: FC<Props> = ({ text, sx }) => {
  return (
    <Typography
      sx={{ fontSize: "1.76rem", fontWeight: 100, ...sx }}
      variant="h1"
    >
      {text}
    </Typography>
  );
};
export default FormGroupTitle;
