import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";

type Props = {
  subLabel?: string;
  info?: any;
  labelColor?: string;
} & TextFieldProps;
const FormLabel: FC<Props> = ({
  labelColor,
  info,
  subLabel,
  name,
  label,
  required,
}) => {
  return (
    <Box height={"100%"}>
      <Box
        height={"100%"}
        sx={{
          minHeight: "29px",
          display: "flex",
        }}
      >
        <Box
          component={"label"}
          sx={{
            fontSize: "0.88rem",
            color: labelColor || "#2C31A6",
            mb: 1,
          }}
          htmlFor={name}
        >
          {label}
        </Box>
        {required && (
          <Typography component={"span"} color={"red"}>
            *
          </Typography>
        )}
      </Box>
      {info && <>{info}</>}

      {subLabel && (
        <Typography
          component={"div"}
          sx={{
            fontSize: "0.88rem",
          }}
          color={"#97A0AF"}
        >
          {subLabel}
        </Typography>
      )}
    </Box>
  );
};
export default FormLabel;
