import React, { FC, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { CommonInput } from "@/components/basic/CommonInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, InputAdornment, Typography } from "@mui/material";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setCookie } from "@/utils/cookieUtils";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const Form: FC<{ isNotLogin?: boolean }> = () => {
  const [errorOpen, setErrorOpen] = useState(false);

  const schema = yup.object().shape({
    mail: yup.string().required("※必須"),
    password: yup.string().required("※必須"),
  });

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mail: "",
      password: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  // const [recaptureStatus, setRecaptureStatus] = useState(false);
  // const [recaptureErrorTextStatus, setRecaptureErrorTextStatus] =
  //   useState(false);
  // const [recaptureId, setRecaptureId] = useState("");

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const updateLogin = async ({
    mail,
    password,
  }: {
    mail: string;
    password: string;
  }) => {
    try {
      const result = await axios.post(
        `${process.env.VITE_API_URL}/admin/login`,
        {
          email: mail,
          password,
        },
      );
      result?.data?.access_token &&
        setCookie("jwt-token", result?.data?.access_token);
      setCookie("mail", mail);
      navigate(`/download`);
    } catch (e) {
      setErrorOpen(true);
    }
  };

  const handleClick = () => {
    // if (process.env.VITE_GOOGLE_KEY !== "") {
    //   window?.grecaptcha?.enterprise?.ready(async () => {
    //     const result = await window.grecaptcha.enterprise.getResponse(
    //       recaptureId,
    //     );
    //     if (!recaptureStatus || !result) {
    //       setRecaptureErrorTextStatus(true);
    //     } else {
    //       setRecaptureErrorTextStatus(false);
    //     }
    //
    //     await handleSubmit(
    //       value => {
    //         result &&
    //           recaptureStatus &&
    //           updateLogin({ mail: value.mail, password: value.password });
    //       },
    //       () => {},
    //     )();
    //   });
    // }

    handleSubmit(
      value => {
        updateLogin({ mail: value.mail, password: value.password });
      },
      () => {},
    )();
  };

  // const element = document.getElementById("html_recaptcha");

  // useEffect(() => {
  //   if (process.env.VITE_GOOGLE_KEY !== "") {
  //     if (isNotLogin && element) {
  //       const key = process.env.VITE_GOOGLE_KEY;
  //       const recaptureWidgetId = window?.grecaptcha?.enterprise?.render(
  //         "html_recaptcha",
  //         {
  //           sitekey: key,
  //           action: "login",
  //           callback: () => {
  //             setRecaptureStatus(true);
  //             setRecaptureErrorTextStatus(false);
  //           },
  //         },
  //       );
  //       setRecaptureId(recaptureWidgetId);
  //     } else {
  //       setRecaptureStatus(true);
  //     }
  //   }
  // }, [isNotLogin, element]);
  return (
    <>
      <CommonInput
        sx={{ paddingBottom: "8px", mb: 2 }}
        name="mail"
        placeholder={"メールアドレスを入力"}
        control={control}
        errors={errors}
        label="アカウント"
        variant="standard"
        labelColor={errors["mail"] ? "#F44336" : "#757575"}
      />
      <CommonInput
        sx={{ paddingBottom: "8px" }}
        name="password"
        placeholder={"パスワードを入力"}
        control={control}
        errors={errors}
        label={"パスワード"}
        variant="standard"
        labelColor={errors["password"] ? "#F44336" : "#757575"}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {/*<form action="?" method="POST">*/}
      {/*  <div id="html_recaptcha" className="origin-left pb-5"></div>*/}
      {/*</form>*/}

      {/*<Typography*/}
      {/*  sx={{*/}
      {/*    mt: 1,*/}
      {/*    fontSize: "0.8rem",*/}
      {/*    fontWeight: 400,*/}
      {/*  }}*/}
      {/*  color={"#F44336"}*/}
      {/*  component="div"*/}
      {/*  className="pb-5"*/}
      {/*>*/}
      {/*  {recaptureErrorTextStatus &&*/}
      {/*    "ロボットによる操作ではないことを確認してください。"}*/}
      {/*</Typography>*/}
      <Button
        sx={{ width: "100%", mt: 5, height: "50px" }}
        onClick={handleClick}
        variant="contained"
      >
        ログイン
      </Button>

      <Typography
        id="top"
        variant="h2"
        component="h1"
        onClick={() => navigate(`/forget-password`)}
        my={3}
        sx={{
          fontSize: "0.8rem",
          textDecoration: "underline",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        パスワードを忘れた場合
      </Typography>

      <Dialog
        disableAutoFocus
        disableRestoreFocus
        open={errorOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },

          "& .MuiDialog-paperWidthMd": {
            minWidth: "600px",
          },
        }}
      >
        <DialogContent
          sx={{
            background: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 5,
            minWidth: {
              sm: "100%",
              md: "600px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: 700,
            }}
            variant="h4"
          >
            ログインエラー
          </Typography>
          <Typography
            sx={{
              mt: 3,
              fontSize: "0.8rem",
              fontWeight: 700,
            }}
          >
            入力されたアカウントまたはパスワードが間違っています。再度お試しください。
          </Typography>

          <Button
            sx={{ width: "30%", mt: 3 }}
            onClick={() => {
              setErrorOpen(false);
            }}
            variant="contained"
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

const LoginForm: FC<{ isNotLogin: boolean }> = ({ isNotLogin }) => {
  return (
    <Box
      mt={2}
      px={8}
      pt={15}
      pb={5}
      sx={{
        backgroundColor: "white",
        borderRadius: "5px",
        width: "60%",
        maxWidth: "518px",
      }}
    >
      <Typography
        id="top"
        variant="h2"
        component="h1"
        my={6}
        sx={{
          fontWeight: 700,
          fontSize: "2.5rem",
        }}
      >
        ログイン
      </Typography>
      <Form isNotLogin={isNotLogin} />
    </Box>
  );
};

export default LoginForm;
