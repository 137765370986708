import React, {
  FC,
  useMemo,
  useState,
  createContext,
  PropsWithChildren,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";

interface FormState {
  page?: number;
  setPage?: (page: number) => void;
}

export const FormProviderContext = createContext<FormState>({
  page: 1,
});

export const FormProvider: FC<PropsWithChildren<FormState>> = ({
  children,
}) => {
  const [page, setPage] = useState(1);
  const { pathname } = useLocation();

  const isDisableBeforeUnload = !pathname.includes("form");

  useEffect(() => {
    const onBeforeUnload = ev => {
      ev.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    if (page === 10 || isDisableBeforeUnload) {
      window.removeEventListener("beforeunload", onBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [page]);

  const context = useMemo(
    () => ({
      page,
      setPage,
    }),
    [page],
  );

  return (
    <FormProviderContext.Provider value={context}>
      {children}
    </FormProviderContext.Provider>
  );
};
