import { Box, Typography } from "@mui/material";
import React, {
  FC,
  FunctionComponent,
  PropsWithChildren,
  useEffect,
} from "react";
import Content from "@/layouts/Content";
import { FormProvider } from "@/components/context/FormProvider";
import { useLocation } from "react-router-dom";
import { TimeProvider } from "@/components/context/TimeProvider";

const Header: FC<{ isShowHeaderImg?: boolean }> = ({
  isShowHeaderImg = true,
}) => {
  return (
    <Box>
      {isShowHeaderImg && (
        <Box sx={{ display: { xs: "none", lg: "flex" }, position: "relative" }}>
          <Box
            component="img"
            sx={{
              height: "100%",
              width: "100%",
            }}
            src="/images/header.png"
          />
          <Typography
            sx={{
              width: "80%",
              position: "absolute",
              fontSize: "2.5rem",
              fontWeight: 600,
              color: "white",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Box>環境価値の地域循環サービスプラットフォーム</Box>
            <Box>申込み入力フォーム</Box>
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          height: "14px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "25%",
            backgroundColor: theme => theme.palette.secondary.main,
          }}
        />
        <Box
          sx={{
            height: "100%",
            width: "85%",
            backgroundColor: theme => theme.palette.primary.main,
          }}
        />
        <Box
          sx={{
            height: "100%",
            width: "10%",
            backgroundColor: theme => theme.palette.info.main,
          }}
        />
      </Box>
    </Box>
  );
};
const MainLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const { pathname } = useLocation();

  const isShowHeaderImg = !(
    pathname.includes("download") || pathname.includes("login")
  );
  return (
    <FormProvider>
      <TimeProvider>
        <Box sx={{ overflow: "hidden", position: "relative" }}>
          <Header isShowHeaderImg={isShowHeaderImg} />
          <Content isShowBg={isShowHeaderImg}>{children}</Content>
          {isShowHeaderImg && (
            <Box
              sx={{
                display: {
                  xs: "none",
                  lg: "flex",
                  overflow: "hidden",
                  height: "100px",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  position: "absolute",
                  height: "15%",
                  zIndex: "-1",
                  bottom: "-100px",
                  left: "-50px",
                }}
                src="/images/greenBall.png"
              />
            </Box>
          )}
        </Box>
      </TimeProvider>
    </FormProvider>
  );
};

export default MainLayout;
