import { SxProps, Theme, Typography } from "@mui/material";
import React, { FC } from "react";
type Props = {
  text: string;
  sx?: SxProps<Theme>;
};
const GroupTitle: FC<Props> = ({ text, sx }) => {
  return (
    <Typography
      sx={{
        fontSize: "0.88rem",
        fontWeight: 700,
        textDecoration: "underline",
        ...sx,
      }}
      variant="h4"
    >
      {text}
    </Typography>
  );
};
export default GroupTitle;
