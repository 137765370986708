import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  TextareaAutosize,
  Typography,
  styled,
} from "@mui/material";
import GroupTitle from "@/components/basic/GroupTitle";
import FormGroupTitle from "@/components/basic/FormGroupTitle";
const StyledTextarea = styled(TextareaAutosize)(
  () => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #BBBCBC;
    textarea {
      resize: both;
      width: 100%;
    }
  `,
);
interface Props {
  pageButton?: React.ElementType;
  onSubmit: (data: any) => void;
  onError: (data: any) => void;
  data: any;
}
const Form: FC<Props> = ({ data, pageButton: PageButton, onSubmit }) => {
  const [checkProtect, setCheckProtect] = useState(data?.checkProtect);
  const [checkPhone, setCheckPhone] = useState(data?.checkPhone);
  const [question, setQuestion] = useState(data?.question);
  const handleClick = data => {
    onSubmit({
      ...data,
      checkProtect,
      checkPhone,
      question,
    });
  };

  return (
    <>
      <GroupTitle
        text={"【個人情報の取り扱いについて】"}
        sx={{ mb: 2, mt: 6 }}
      />
      <Grid mb={2} container spacing={1}>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              defaultChecked={data?.checkProtect}
              id={"checkProtect"}
              onChange={value => setCheckProtect(value.target.checked)}
            />
            <Box
              component={"label"}
              sx={{
                fontSize: "0.88rem",
                color: "#202C40",
                mb: 1,
              }}
              htmlFor={"checkProtect"}
            >
              <Typography
                component={"a"}
                target="_blank"
                href={
                  "https://www.cosmo-energy.co.jp/ja/privacy/cosmo-com/policy.html"
                }
                sx={{
                  fontSize: "0.88rem",
                  fontWeight: 700,
                  color: "#2C31A6",
                }}
              >
                プライバシーポリシー
              </Typography>
              を確認し、同意します。
              <Typography component={"span"} color={"red"}>
                *
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              defaultChecked={data?.checkPhone}
              id={"checkPhone"}
              onChange={value => setCheckPhone(value.target.checked)}
            />
            <Box
              component={"label"}
              sx={{
                fontSize: "0.88rem",
                color: "#202C40",
                mb: 1,
              }}
              htmlFor={"checkPhone"}
            >
              弊社からの携帯電話またはメールによる連絡に同意します。
              <Typography component={"span"} color={"red"}>
                *
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <GroupTitle text={"【ご質問など】"} sx={{ mb: 2, mt: 6 }} />
      <Grid mb={2} container spacing={2}>
        <Grid item xs={12} sm={12}>
          <StyledTextarea
            value={data?.question}
            maxLength={500}
            minRows={10}
            onChange={value => setQuestion(value.target.value)}
          />
        </Grid>
      </Grid>
      {PageButton && (
        <PageButton
          isEnableButton={!!checkProtect && !!checkPhone}
          onClickSubmit={handleClick}
        ></PageButton>
      )}
    </>
  );
};
const SimpleContractForm: FC<Props> = ({
  data,
  onError,
  pageButton,
  onSubmit,
}) => {
  useEffect(() => {
    gtag("event", "page_view", {
      page_title: `TypeA-STEP2（利用規約）`,
      page_location: `/form/a/tos`,
    });
  }, []);
  return (
    <Box
      mt={2}
      p={2}
      sx={{
        backgroundColor: "#F0F0F0",
        borderRadius: "5px",
      }}
    >
      <FormGroupTitle text={"利用規約"} />
      <Form
        pageButton={pageButton}
        onSubmit={onSubmit}
        onError={onError}
        data={data}
      />
    </Box>
  );
};

export default SimpleContractForm;
