import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function RemoveIcon() {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 19.7143C5 20.9714 6.05 22 7.33333 22H16.6667C17.95 22 19 20.9714 19 19.7143V6H5V19.7143ZM10.5004 9.40015C10.7986 9.40015 11.046 9.61774 11.0925 9.90282L11.1004 10.0001V18.0001C11.1004 18.3315 10.8318 18.6001 10.5004 18.6001C10.2022 18.6001 9.95474 18.3826 9.90824 18.0975L9.90039 18.0001V10.0001C9.90039 9.66878 10.169 9.40015 10.5004 9.40015ZM14.5925 9.90282C14.546 9.61774 14.2986 9.40015 14.0004 9.40015C13.669 9.40015 13.4004 9.66878 13.4004 10.0001V18.0001L13.4082 18.0975C13.4547 18.3826 13.7022 18.6001 14.0004 18.6001C14.3318 18.6001 14.6004 18.3315 14.6004 18.0001V10.0001L14.5925 9.90282Z"
          fill="#1B2433"
        />
        <path d="M20 3H16L14.8571 2H9.14286L8 3H4V5H20V3Z" fill="#1B2433" />
      </svg>
    </SvgIcon>
  );
}
