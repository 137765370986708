import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { CommonInput } from "@/components/basic/CommonInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import GroupTitle from "@/components/basic/GroupTitle";
import FormGroupTitle from "@/components/basic/FormGroupTitle";
import { CommonSelect } from "@/components/basic/CommonSelect";
import { CommonUpload } from "@/components/basic/CommonUpload";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import InfoIcon from "@/components/icons/InfoIcon";
import Button from "@mui/material/Button";
import { useFormType } from "@/components/share/useFormType";
interface Props {
  pageButton?: React.ElementType;
  onSubmit: (data: any) => void;
  onError: (data: any) => void;
  data: any;
}
const Info = () => {
  return (
    <Box
      my={2}
      p={2}
      sx={{
        backgroundColor: theme => theme.palette.secondary.main,
        borderRadius: "5px",
      }}
    >
      <GroupTitle
        sx={{ color: "#FFFFFF", textDecoration: "none" }}
        text={
          "卒FITなど太陽光発電設備の設置が2年を超えていても、蓄電池・電気自動車・エコキュートのいずれかの設置から2年以内であれば会員の対象となります。 "
        }
      />
      <Box mt={2}>
        <GroupTitle
          sx={{ color: "#FFFFFF", textDecoration: "none" }}
          text={
            "ただし、電気自動車の途中売却など、追加設備を処分した場合は会員資格が失効します。"
          }
        />
      </Box>
    </Box>
  );
};

const Form: FC<Props> = ({
  data,
  onError,
  pageButton: PageButton,
  onSubmit,
}) => {
  const [proveFile, setProveFile] = useState(data?.proveFile || "");
  const [systemFile, setSystemFile] = useState(data?.systemFile || "");
  const [buyProveFile, setBuyProveFile] = useState(null);
  const [carProveFile, setCarProveFile] = useState(null);
  const [deviceStatus, setDeviceStatus] = useState(data?.deviceStatus || "yes");
  const schema = yup.object().shape({
    deviceStatus: yup.string().nullable(),
    price: yup.string().nullable(),
    battleDisChargePercent: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, {
        message: "入力できるのは正数および小数点のみです。",
        excludeEmptyString: true,
      })
      .nullable(),
    battleChargePercent: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, {
        message: "入力できるのは正数および小数点のみです。",
        excludeEmptyString: true,
      })
      .nullable(),
    battlePower: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, {
        message: "入力できるのは正数および小数点のみです。",
        excludeEmptyString: true,
      })
      .nullable(),
    proveFile: yup.string().nullable(),
    systemFile: yup.string().nullable(),
    buyProveFile: yup.string().nullable(),
    carProveFile: yup.string().nullable(),
  });
  const {
    getValues,
    control,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      deviceStatus: data?.deviceStatus || "yes",
      battleChargePercent: data?.battleChargePercent || "",
      battleDisChargePercent: data?.battleDisChargePercent || "",
      battlePower: data?.battlePower || "",
      price: data?.price || "",
      proveFile,
      systemFile,
      carProveFile,
      buyProveFile,
    },
  });
  const [openInfo, setOpenInfo] = useState(false);
  const handleClick = data => {
    handleSubmit(
      value => {
        onSubmit({
          systemFile,
          proveFile,
          carProveFile,
          buyProveFile,
          price: value.price,
          battleChargePercent: value.battleChargePercent,
          battleDisChargePercent: value.battleDisChargePercent,
          battlePower: value.battlePower,
          deviceStatus: value.deviceStatus,
          ...data,
        });
      },
      value => {
        onError(value);
      },
    )();
  };

  const optionValue = [
    { label: "有", value: "yes" },
    { label: "無", value: "no" },
  ];
  return (
    <>
      <GroupTitle text={"【追加設備について】"} sx={{ mb: 3, mt: 4 }} />
      <Info />
      <Dialog open={openInfo}>
        <DialogContent
          sx={{
            background: "white",
            padding: "16px",
          }}
        >
          <GroupTitle text={"【サンプル】"} sx={{ mb: 3, mt: 4 }} />

          <Box
            component="img"
            sx={{
              height: "100%",
              width: "100%",
            }}
            alt="The house from the offer."
            src="/images/info.png"
          />
          <Button
            sx={{ width: "100%" }}
            onClick={() => setOpenInfo(false)}
            variant="outlined"
          >
            閉じる
          </Button>
        </DialogContent>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <CommonSelect
            defaultValue={data?.deviceStatus || "yes"}
            required={true}
            control={control}
            errors={errors}
            name={"deviceStatus"}
            placeholder={"選択する"}
            label={"追加設備の有無"}
            onChangeAction={() => {
              const values = getValues();
              setDeviceStatus(values.deviceStatus);
              handleClick({});
            }}
          >
            {optionValue?.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </CommonSelect>
        </Grid>
      </Grid>
      {deviceStatus === "yes" && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <GroupTitle sx={{ mb: 1, mt: 6 }} text={"A)蓄電池"} />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <CommonInput
                defaultValue={data?.battlePower || ""}
                sx={{ paddingBottom: "8px" }}
                name="battlePower"
                placeholder={"入力する"}
                control={control}
                errors={errors}
                label={"蓄電池容量（kWh）"}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <CommonInput
                defaultValue={data?.battleChargePercent || ""}
                sx={{ paddingBottom: "8px" }}
                name="battleChargePercent"
                placeholder={"入力する"}
                control={control}
                errors={errors}
                label={"蓄電池充電効率（%）"}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <CommonInput
                defaultValue={data?.battleDisChargePercent || ""}
                sx={{ paddingBottom: "8px" }}
                name="battleDisChargePercent"
                placeholder={"入力する"}
                control={control}
                errors={errors}
                label={"蓄電池放電効率（%）"}
              />
            </Grid>
          </Grid>
          <Box my={3}></Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <>
                <CommonUpload
                  defaultValue={data?.proveFile || ""}
                  onChangeAction={value => setProveFile(value)}
                  subLabel={"※契約書・工事完了書など"}
                  sx={{ paddingBottom: "8px" }}
                  name="proveFile"
                  placeholder={""}
                  control={control}
                  errors={errors}
                  label={"稼働開始日の証明書類（PNG、JPG、JPEG、PDF、最大5MB）"}
                  info={
                    <Box
                      component={"span"}
                      sx={{ display: "inline-flex", cursor: "pointer" }}
                      onClick={event => {
                        event.stopPropagation();

                        setOpenInfo(true);
                      }}
                    >
                      <InfoIcon fontSize={"small"} />
                      <Typography
                        sx={{
                          fontSize: "0.88rem",
                          fontWeight: 700,
                          textDecoration: "underline",
                          color: "#97A0AF",
                        }}
                      >
                        参照
                      </Typography>
                    </Box>
                  }
                />
              </>
            </Grid>
            <Grid item xs={12} md={6}>
              <>
                <CommonUpload
                  defaultValue={data?.systemFile || ""}
                  onChangeAction={value => setSystemFile(value)}
                  subLabel={
                    "※蓄電池の容量、充放電効率が確認できる仕様書またはカタログなど"
                  }
                  sx={{ paddingBottom: "8px" }}
                  name="systemFile"
                  placeholder={""}
                  control={control}
                  errors={errors}
                  label={"システム関連書類（PNG、JPG、JPEG、PDF、最大5MB）\n"}
                />
              </>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <GroupTitle sx={{ mt: 6 }} text={"B)電気自動車（PHEV含む）"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <>
                <CommonUpload
                  defaultValue={data?.carProveFile || ""}
                  onChangeAction={value => setCarProveFile(value)}
                  sx={{ paddingBottom: "8px" }}
                  name="carProveFile"
                  placeholder={""}
                  control={control}
                  errors={errors}
                  label={
                    "購入証明書類（車検証など PNG、JPG、JPEG、PDF、最大5MB）"
                  }
                />
              </>
            </Grid>
            <Grid item xs={12} md={3}>
              <CommonSelect
                defaultValue={data?.price || null}
                control={control}
                errors={errors}
                name={"price"}
                placeholder={"選択する"}
                label={"補助金利用の有無"}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return (
                      <Typography
                        sx={{
                          fontSize: "0.88rem",
                          fontWeight: 100,
                          color: "#97A0AF",
                        }}
                      >
                        選択する
                      </Typography>
                    );
                  }

                  return selected === "yes" ? "有" : "無";
                }}
              >
                {optionValue?.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </CommonSelect>
            </Grid>
            <Grid item xs={12} md={3}></Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <GroupTitle sx={{ mt: 6 }} text={"C)エコキュート"} />
            </Grid>
            <Grid item xs={12} md={7}>
              <>
                <CommonUpload
                  onChangeAction={value => setBuyProveFile(value)}
                  defaultValue={data?.buyProveFile || ""}
                  subLabel={"※契約書または工事完了書など"}
                  sx={{ paddingBottom: "8px" }}
                  name="buyProveFile"
                  placeholder={""}
                  control={control}
                  errors={errors}
                  label={"購入・設置証明書類（PNG、JPG、JPEG、PDF、最大5MB）"}
                  info={
                    <Box
                      sx={{ m: 0, display: "inline-flex", cursor: "pointer" }}
                      onClick={() => setOpenInfo(true)}
                    >
                      <InfoIcon fontSize={"small"} />
                      <Typography
                        sx={{
                          fontSize: "0.88rem",
                          fontWeight: 700,
                          textDecoration: "underline",
                          color: "#97A0AF",
                        }}
                      >
                        参照
                      </Typography>
                    </Box>
                  }
                />
              </>
            </Grid>
          </Grid>
        </>
      )}
      {PageButton && <PageButton onClickSubmit={handleClick}></PageButton>}
    </>
  );
};
const MoreDeviceTypeCForm: FC<Props> = ({
  data,
  onError,
  pageButton,
  onSubmit,
}) => {
  const type = useFormType();

  useEffect(() => {
    gtag("event", "page_view", {
      page_title: `Type${type}-STEP3（追加設備）`,
      page_location: `/form/${type?.toLowerCase()}/additionalfacilities`,
    });
  }, []);
  return (
    <Box
      mt={2}
      p={2}
      sx={{
        backgroundColor: "#F0F0F0",
        borderRadius: "5px",
      }}
    >
      <FormGroupTitle text={"追加設備情報"} sx={{ mb: 1 }} />
      <Typography sx={{ fontSize: "0.88rem", fontWeight: 100, color: "red" }}>
        ※２年以内に設置した追加設備いずれかをご記入ください。{" "}
      </Typography>
      <Form
        pageButton={pageButton}
        onSubmit={onSubmit}
        onError={onError}
        data={data}
      />
    </Box>
  );
};

export default MoreDeviceTypeCForm;
