import * as React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./pages";
import { BrowserRouter } from "react-router-dom";

function resizeFullHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

resizeFullHeight();
window.addEventListener("resize", () => {
  resizeFullHeight();
});
declare global {
  interface Window {
    grecaptcha: any;
  }
}
createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
