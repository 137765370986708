import LoginForm from "@/components/form/LoginForm";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { getCookie } from "@/utils/cookieUtils";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [isNotLogin, setIsNotLogin] = useState(false);

  const navigate = useNavigate();

  const fetchLoginStatus = async () => {
    try {
      const token = getCookie("jwt-token");
      await axios.post(
        `${process.env.VITE_API_URL}/admin/check`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      navigate(`/download`);
    } catch (e) {
      setIsNotLogin(true);
      //console.log(e);
    }
  };
  useEffect(() => {
    fetchLoginStatus();
  }, []);
  return (
    <>
      {isNotLogin ? (
        <>
          <Box
            sx={{
              width: "100%",
              height: "14px",
              display: "flex",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "25%",
                backgroundColor: theme => theme.palette.secondary.main,
              }}
            />
            <Box
              sx={{
                height: "100%",
                width: "85%",
                backgroundColor: theme => theme.palette.primary.main,
              }}
            />
            <Box
              sx={{
                height: "100%",
                width: "10%",
                backgroundColor: theme => theme.palette.info.main,
              }}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "auto",
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 5,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundImage: 'url("/images/header.png")',
            }}
          >
            <Box
              sx={{
                width: "40%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: 600,
                  color: "white",
                }}
              >
                <Box>環境価値の地域循環</Box>
                <Box>プラットフォームサービス</Box>

                <Box>会員情報管理ポータル</Box>
              </Typography>
            </Box>
            <LoginForm isNotLogin={isNotLogin} />
          </Box>
        </>
      ) : null}
    </>
  );
};

export default Login;
