import React, { FC, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { CommonInput } from "@/components/basic/CommonInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
// import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Form: FC = () => {
  const schema = yup.object().shape({
    mail: yup
      .string()
      .email("正しいメールアドレスを入力してください。")
      // .matches(/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/, {
      //   message: "正しいメールアドレスを入力してください。",
      //   excludeEmptyString: true,
      // })
      .required("メールは入力必須項目です。"),
  });

  // const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mail: "",
    },
  });

  const sendMail = async ({ mail }: { mail: string }) => {
    try {
      await axios.post(`${process.env.VITE_API_URL}/admin/forgot_password`, {
        email: mail,
      });
      setIsError(false);
      setOpen(true);
      setTitle("パスワードリセット用のメールを送信しました");
      setContent(
        "メールボックスを確認し、メールからパスワードリセットの操作を行なってください。",
      );
    } catch (e) {
      const errorMsg = e?.response?.status;
      setIsError(true);
      setOpen(true);
      setTitle("問題が発生しました");
      setContent(
        `問題が発生しました。しばらくしてから再度お試しいただくか、サービスプロバイダまでお問い合わせください。${
          errorMsg ? "(" + errorMsg + ")" : ""
        }`,
      );
    }
  };

  const handleClick = () => {
    handleSubmit(
      value => {
        sendMail({ mail: value.mail });
      },
      () => {},
    )();
  };

  return (
    <>
      <CommonInput
        sx={{ paddingBottom: "8px" }}
        name="mail"
        placeholder={"メールアドレスを入力"}
        control={control}
        errors={errors}
        label="アカウント"
        variant="standard"
        labelColor={errors["mail"] ? "#F44336" : "#757575"}
      />

      <Button
        sx={{ width: "100%", mt: 5, height: "50px" }}
        onClick={handleClick}
        variant="contained"
      >
        パスワードリセットリンクを送信
      </Button>
      <Dialog
        disableAutoFocus
        disableRestoreFocus
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },

          "& .MuiDialog-paperWidthMd": {
            minWidth: "600px",
          },
        }}
      >
        <DialogContent
          sx={{
            background: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 5,
            minWidth: {
              sm: "100%",
              md: "600px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: 700,
            }}
            variant="h4"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              mt: 3,
              fontSize: "0.8rem",
              fontWeight: 700,
            }}
          >
            {content}
          </Typography>
          <Button
            sx={{ width: "30%", mt: 3 }}
            onClick={() => {
              setOpen(false);
              !isError && navigate(`/login`);
            }}
            variant="contained"
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ForgetPwdForm: FC = () => {
  return (
    <Box
      mt={2}
      p={2}
      sx={{
        backgroundColor: "white",
        borderRadius: "5px",
        width: "50%",
        maxWidth: "600px",
      }}
    >
      <Typography
        id="top"
        variant="h2"
        component="h1"
        my={6}
        sx={{
          fontWeight: 700,
          fontSize: "2.5rem",
        }}
      >
        パスワード再設定
      </Typography>
      <Form />
    </Box>
  );
};

export default ForgetPwdForm;
