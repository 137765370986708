import React, { FunctionComponent } from "react";
import { ReactElement, Suspense } from "react";
import { Outlet, useLocation, useRoutes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import FormA from "@/pages/FormA";
import MainLayout from "@/layouts/MainLayout";
import FormB from "@/pages/FormB";
import FormC from "@/pages/FormC";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Download from "./Download";
import Login from "@/pages/Login";
import ForgetPwd from "@/pages/ForgetPwd";
import ResetPwd from "./ResetPwd";
import { Helmet } from "react-helmet";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    dashed: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsVariantOverrides {
    title: true;
  }
}
const BasicComponent: FunctionComponent<{ isNeedLayout?: boolean }> = ({
  isNeedLayout = true,
}) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 350,
        md: 768,
        lg: 1024,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: [
        "游ゴシック体",
        "YuGothic",
        "游ゴシック",
        "Yu Gothic",
        "凸版文久見出しゴシック",
        "見出しゴシック",
        "MS ゴシック",
        "MS Gothic",
        "ＭＳ ゴシック",
        "Noto Sans JP",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },

    components: {
      // MuiCssBaseline: {
      //   styleOverrides: (themeParam) => ({
      //     body:,
      //   }),
      // },
      // MuiButton: {
      //   variants: [
      //     {
      //       props: { variant: "dashed" },
      //       style: {
      //         textTransform: "none",
      //         border: `2px dashed ${blue[500]}`,
      //       },
      //     },
      //     {
      //       props: { variant: "dashed", color: "secondary" },
      //       style: {
      //         border: `4px dashed ${red[500]}`,
      //       },
      //     },
      //   ],
      // },
    },

    palette: {
      error: {
        main: "#F44336",
      },

      primary: {
        main: "#2C31A6",
      },
      secondary: {
        main: "#00AD92",
      },
      info: {
        main: "#FC2E14",
      },
      background: {
        default: "#FFFFFF",
        paper: "#FFFFFF",
      },
    },
  });
  const { pathname } = useLocation();
  const isForm = pathname.includes("form");
  const titleName = isForm
    ? "環境価値の地域循環サービスプラットフォーム申込み入力フォーム"
    : "環境価値の地域循環プラットフォームサービス会員情報管理ポータル";

  return (
    <Suspense>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Helmet>
            <title>{titleName}</title>
          </Helmet>
          {/*{props.children}*/}
          {isNeedLayout ? (
            <MainLayout>
              <Outlet />
            </MainLayout>
          ) : (
            <Outlet />
          )}
        </LocalizationProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export function App(): ReactElement | null {
  return useRoutes([
    {
      path: "/form",
      element: <BasicComponent />,
      children: [
        {
          path: "a",
          element: <FormA />,
        },
        {
          path: "b",
          element: <FormB />,
        },
        {
          path: "c",
          element: <FormC />,
        },
      ],
    },
    {
      path: "/download",
      element: <BasicComponent />,
      children: [
        {
          path: "",
          element: <Download />,
        },
      ],
    },
    {
      path: "/login",
      element: <BasicComponent isNeedLayout={false} />,
      children: [
        {
          path: "",
          element: <Login />,
        },
      ],
    },
    {
      path: "/forget-password",
      element: <BasicComponent isNeedLayout={false} />,
      children: [
        {
          path: "",
          element: <ForgetPwd />,
        },
      ],
    },
    {
      path: "/reset-password",
      element: <BasicComponent isNeedLayout={false} />,
      children: [
        {
          path: "",
          element: <ResetPwd />,
        },
      ],
    },
    {
      path: "/reset-password/:token",
      element: <BasicComponent isNeedLayout={false} />,
      children: [
        {
          path: "",
          element: <ResetPwd />,
        },
      ],
    },

    {
      path: "*",
      element: <></>,
    },
  ]);
}
