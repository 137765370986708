import React, { useEffect, useState } from "react";
import GroupTitle from "@/components/basic/GroupTitle";
import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  TablePagination,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import axios from "axios";
import { format } from "date-fns";
import { ArrowDropDown } from "@mui/icons-material";
import { deleteCookie, getCookie } from "@/utils/cookieUtils";
import { useNavigate } from "react-router-dom";

const NoData = () => {
  return (
    <Typography
      sx={{
        fontSize: "0.88rem",
        fontWeight: 100,
        color: "#97A0AF",
        width: "100%",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      まだデータがありません。
    </Typography>
  );
};
export const Table = () => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [isDownloadProcessing, setIsDownloadProcessing] = React.useState(false);

  const [rows, setRows] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);

  const [errorOpen, setErrorOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isNeedRedirect, setIsNeedRedirect] = useState(false);
  const navigate = useNavigate();

  const setErrorStatus = (error: any) => {
    const errorMsg = error?.response?.data?.code;
    if (errorMsg.toString() === "40102") {
      setIsNeedRedirect(true);
    }
    if (errorMsg.toString() === "40103") {
      setIsNeedRedirect(true);
      setErrorOpen(true);
      setTitle("接続がタイムアウトしました");
      setContent(
        `プラットフォームの利用を続けるには、再度ログインしてください。`,
      );
      return;
    }
    setErrorOpen(true);
    setTitle("問題が発生しました");
    setContent(
      `問題が発生しました。しばらくしてから再度お試しいただくか、サービスプロバイダまでお問い合わせください。${
        errorMsg ? "(" + errorMsg + ")" : ""
      }`,
    );
  };

  const downloadList = async () => {
    try {
      setIsDownloadProcessing(true);
      const token = getCookie("jwt-token");

      const response = await axios.post(
        `${process.env.VITE_API_URL}/application_form/download`,
        {
          appl_id_list: selectedRows,
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const result = response.data;
      const a = document.createElement("a");
      document.body.appendChild(a);
      const blob: any = new Blob([result], {
        type: "application/octet-stream",
      });

      const url = window.URL.createObjectURL(blob);
      a.href = url;
      const name = format(new Date(), "yyyyMMddHHmm");
      a.download = `jcf_${name}.zip`;
      a.click();
    } catch (e) {
      setErrorStatus(e);
    } finally {
      setIsDownloadProcessing(false);

      fetDownloadList({ newPage: page, newPerPage: rowsPerPage });
    }
  };
  const deleteList = async () => {
    try {
      const token = getCookie("jwt-token");

      await axios.post(
        `${process.env.VITE_API_URL}/application_form/delete`,
        {
          appl_id_list: selectedRows,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (e) {
      setErrorStatus(e);
    } finally {
      fetDownloadList({ newPage: page, newPerPage: rowsPerPage });
    }
  };

  useEffect(() => {
    fetDownloadList({ newPage: page, newPerPage: rowsPerPage });
  }, [page, rowsPerPage]);

  const fetDownloadList = async ({
    newPage,
    newPerPage,
  }: {
    newPage: number;
    newPerPage: number;
  }) => {
    try {
      const token = getCookie("jwt-token");
      const result = await axios.post(
        `${process.env.VITE_API_URL}/application_form/list`,
        {
          page: (newPage + 1).toString(),
          per_page: newPerPage.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const saveData = result?.data.list?.map(item => {
        return {
          id: item?.["form_id"],
          createDate: item?.["apply_date"],
          expireDate: item?.["expire_date"],
          name: item?.["name"],
          mobile: item?.["phone_no"],
          mail: item?.["email"],
          record: item?.["last_download_date"],
        };
      });
      setRows(saveData);
      setTotalCount(result?.data?.total_elements);
    } catch (e) {
      setErrorStatus(e);
    }
  };

  const handleRemoveItem = () => {
    deleteList();
    setOpenDelete(false);
  };
  const handleRemove = () => {
    setOpenDelete(true);
  };

  const columns: GridColDef[] = [
    {
      field: "createDate",
      headerName: "申込日",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      // flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box>{params.value || "- -"}</Box>
      ),
      // width: 140,
    },

    {
      field: "expireDate",
      headerName: "有効期限",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      // flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box>{params.value || "- -"}</Box>
      ),
      // width: 140,
    },
    {
      field: "name",
      headerName: "名前",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      //
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box>{params.value || "- -"}</Box>
      ),
      width: 140,
    },
    {
      field: "mobile",
      headerName: "携帯電話番号",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      width: 140,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box>{params.value || "- -"}</Box>
      ),
    },
    {
      field: "mail",
      headerName: "メールアドレス",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box>{params.value || "- -"}</Box>
      ),
    },
    {
      field: "record",
      headerName: "ダウンロード記録",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          {params.value ? (
            <Box>
              <Box>ダウンロード済み</Box>
              <Box>最終：{params.value}</Box>
            </Box>
          ) : (
            <Box>未ダウンロード</Box>
          )}
        </>
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",

        "& .super-app-theme--header": {
          backgroundColor: "#F7F8FA",
          color: "#2C31A6",
        },

        "& .MuiDataGrid-columnHeaderCheckbox": {
          backgroundColor: "#F7F8FA",
          color: "#2C31A6",
        },
      }}
    >
      <Box
        mt={4}
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Button
            onClick={downloadList}
            sx={{ mr: 3, width: "150px", py: 1.2 }}
            variant="contained"
            disabled={!selectedRows.length || isDownloadProcessing}
          >
            ダウンロード
            {isDownloadProcessing && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  // top: "50%",
                  // left: "50%",
                }}
              />
            )}
          </Button>
        </Box>

        <Button
          sx={{ width: "150px", py: 1.2 }}
          color={"info"}
          variant="contained"
          onClick={() => handleRemove()}
          disabled={!selectedRows.length}
        >
          削除
        </Button>
      </Box>
      <GroupTitle text={"ダウンロードリスト"} sx={{ mb: 3 }} />

      <Dialog
        disableAutoFocus
        disableRestoreFocus
        open={openDelete}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },

          "& .MuiDialog-paperWidthMd": {
            minWidth: "600px",
          },
        }}
      >
        <DialogContent
          sx={{
            background: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: {
              sm: "100%",
              md: "600px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: 700,
            }}
            variant="h4"
          >
            削除しますか？
          </Typography>
          <Typography
            sx={{
              my: 3,
              fontSize: "0.8rem",
              fontWeight: 700,
            }}
          >
            この操作は元に戻すことができません。
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{ width: "150px", py: 1.2, color: "#42526E" }}
              onClick={() => setOpenDelete(false)}
            >
              キャンセル
            </Button>
            <Button
              sx={{ width: "150px", py: 1.2 }}
              color={"info"}
              onClick={handleRemoveItem}
              variant="contained"
            >
              削除
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        disableAutoFocus
        disableRestoreFocus
        open={errorOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },

          "& .MuiDialog-paperWidthMd": {
            minWidth: "600px",
          },
        }}
      >
        <DialogContent
          sx={{
            background: "white",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 5,
            minWidth: {
              sm: "100%",
              md: "600px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: 700,
            }}
            variant="h4"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              mt: 3,
              fontSize: "0.8rem",
              fontWeight: 700,
            }}
          >
            {content}
          </Typography>

          <Button
            sx={{ width: "30%", mt: 3 }}
            onClick={() => {
              setErrorOpen(false);
              deleteCookie("mail");
              deleteCookie("jwt-token");
              isNeedRedirect && navigate(`/login`);
            }}
            variant="contained"
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>

      <DataGrid
        // pageSize={10}
        // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        slots={{
          noRowsOverlay: NoData,
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: "ページごとの表示数：",
          },
        }}
        sx={{
          overflowX: "scroll",
          border: "none",
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1.5,
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
        }}
        autoHeight={true}
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableDensitySelector
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={ids => {
          setSelectedRows(ids);
        }}
        checkboxSelection
        hideFooter={true}
      />
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        labelRowsPerPage={"ページごとの表示数："}
        rowsPerPage={rowsPerPage}
        onPageChange={(
          event: React.MouseEvent<HTMLButtonElement> | null,
          newPage: number,
        ) => {
          setPage(newPage);
          fetDownloadList({ newPage: newPage, newPerPage: rowsPerPage });
        }}
        onRowsPerPageChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
          const perPage = parseInt(event.target.value, 10);
          setRowsPerPage(perPage);
          setPage(0);
        }}
      />
    </Box>
  );
};
const Download = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [userName, setUserName] = useState("Guest");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const fetchLoginStatus = async () => {
    try {
      const token = getCookie("jwt-token");
      await axios.post(
        `${process.env.VITE_API_URL}/admin/check`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const name = getCookie("mail");
      setUserName(name);
      setIsLogin(true);
    } catch (e) {
      navigate(`/login`);
    }
  };
  useEffect(() => {
    fetchLoginStatus();
  }, []);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    try {
      const token = getCookie("jwt-token");
      await axios.post(
        `${process.env.VITE_API_URL}/admin/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      deleteCookie("mail");
      deleteCookie("jwt-token");
      navigate(`/login`);
    } catch (e) {
      //console.log(e);
    }
  };

  return (
    <>
      {isLogin ? (
        <>
          <Box
            component="img"
            sx={{
              height: "17%",
              width: "17%",
              position: "absolute",
              left: 0,
            }}
            src="/images/download.svg"
          />

          <Box sx={{ display: "flex", justifyContent: "end", mt: 5 }}>
            <Button onClick={handleClick} endIcon={<ArrowDropDown />}>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 700,
                  color: "#42526E",
                  textTransform: "none",
                }}
              >
                <Box>{userName}</Box>
              </Typography>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
            </Menu>
          </Box>
          <Box
            mt={10}
            p={2}
            sx={{
              zIndex: 1,
              boxShadow: "0px 2px 4px 0px #00000045",
              background: "white",
            }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontWeight: 700,
                fontSize: "2.5rem",
              }}
            >
              ダウンロード
            </Typography>
            <Table />
          </Box>
        </>
      ) : null}
    </>
  );
};

export default Download;
