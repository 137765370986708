import React, {
  FC,
  useMemo,
  useState,
  createContext,
  PropsWithChildren,
  useEffect,
  useRef,
} from "react";

interface State {
  reset?: boolean;
  setReset?: (status: boolean) => void;
  timePopupStatus?: boolean;
  setTimePopStatus?: (status: boolean) => void;
  stopTimeout?: () => void;
}

export const TimeProviderContext = createContext<State>({
  reset: true,
  timePopupStatus: false,
});

export const TimeProvider: FC<PropsWithChildren<State>> = ({ children }) => {
  const [reset, setReset] = useState(true);
  const [timePopupStatus, setTimePopStatus] = useState(false);

  const timer = useRef<ReturnType<typeof setInterval> | null>(null);
  const timeOut = () => {
    const time = process.env.VITE_TIMEOUT || 7200;

    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setTimePopStatus && setTimePopStatus(true);
    }, +time);
  };

  useEffect(() => {
    if (reset) {
      timeOut();
      setReset && setReset(false);
    }
  }, [reset]);

  const stopTimeout = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  useEffect(() => {
    timeOut();

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const context = useMemo(
    () => ({
      reset,
      setReset,
      timePopupStatus,
      setTimePopStatus,
      stopTimeout,
    }),
    [reset, timePopupStatus],
  );

  return (
    <TimeProviderContext.Provider value={context}>
      {children}
    </TimeProviderContext.Provider>
  );
};
