import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";
interface Props {
  children?: ReactNode;
  isShowBg?: boolean;
}
const Content: FC<Props> = ({ children, isShowBg }) => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      {isShowBg && (
        <Box sx={{ display: { xs: "none", lg: "flex" } }}>
          <Box
            component="img"
            sx={{
              position: "absolute",
              zIndex: "-1",
              height: "800px",
              top: "200px",
            }}
            src="/images/blueBall.png"
          />
          <Box
            component="img"
            sx={{
              zIndex: "-1",
              position: "absolute",
              height: "600px",
              top: "600px",
              right: "-200px",
            }}
            src="/images/vector.svg"
          />
        </Box>
      )}
      <Box
        mx={"auto"}
        sx={{
          width: { md: "90%", xs: "95%", lg: "85%" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
export default Content;
