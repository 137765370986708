import ForgetPwdForm from "@/components/form/ForgetPwdForm";
import { Box } from "@mui/material";
import React from "react";

const ForgetPwd = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "14px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "25%",
            backgroundColor: theme => theme.palette.secondary.main,
          }}
        />
        <Box
          sx={{
            height: "100%",
            width: "85%",
            backgroundColor: theme => theme.palette.primary.main,
          }}
        />
        <Box
          sx={{
            height: "100%",
            width: "10%",
            backgroundColor: theme => theme.palette.info.main,
          }}
        />
      </Box>
      <Box
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ForgetPwdForm />
      </Box>
    </>
  );
};

export default ForgetPwd;
