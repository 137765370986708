import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function EditIcon() {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1765 3.89453L7 13.071V15.8945H9.82353L19 6.71806L16.1765 3.89453Z"
          fill="#1B2433"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 2.82494L20.069 4.89453L22 2.96299L19.931 0.894531L18 2.82494Z"
          fill="#1B2433"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 21.8945H2V5.68763C2 3.59522 3.67688 1.89453 5.74 1.89453H11.18V3.9635H5.74C4.80296 3.9635 4.04 4.73729 4.04 5.68763V19.8256H16.96V13.3318H19V21.8945Z"
          fill="#1B2433"
        />
      </svg>
    </SvgIcon>
  );
}
